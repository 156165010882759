import { For } from 'solid-js';
import { ResponsiveNavigationMenu, NavigationMenuItem } from '@troon/ui';
import { Grid, GridThird, GridTwoThirds } from '../components/layouts/grid';
import { Content } from '../components/content';
import { GenericLayout } from './generic';
import type { ComponentProps, ParentProps } from 'solid-js';

type Props = ParentProps<{
	sidebar: Array<ComponentProps<typeof NavigationMenuItem>>;
}>;

export function SidebarLayout(props: Props) {
	return (
		<GenericLayout>
			<Content>
				<Grid>
					<GridThird>
						<ResponsiveNavigationMenu trigger="My account">
							<For each={props.sidebar}>
								{(item) => (
									<NavigationMenuItem href={item.href!} icon={item.icon}>
										{item.children}
									</NavigationMenuItem>
								)}
							</For>
						</ResponsiveNavigationMenu>
					</GridThird>
					<GridTwoThirds>{props.children}</GridTwoThirds>
				</Grid>
			</Content>
		</GenericLayout>
	);
}
